<template>
    <ul class="nav-select">
        <li :class="currentType==index?'current':''"
            v-for="(item,index) in selectList" 
            @click="chooseNavItem(item,index)">{{item}}</li>
    </ul>
</template>

<script>
	export default {
		name:"nav-select",
		props:['selectList'],
        data(){
			return{
				currentType:0
            }
        },
        methods:{
			chooseNavItem(item,index){
				this.currentType = index
			    this.$emit("chooseNavItem",index)	
            },
        }
	}
</script>


<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/assets/style/public.scss";

/*项目选择*/
.nav-select{
    display:flex;
    background: #fff;
    border-bottom: #f2f2f2 1px solid;
    li{
        flex:1;
        font-size: 28px;
        color: #666;
        line-height:1;
        padding: 30px 0;
        text-align: center;
    }
    .current{
        position:relative;
        color:$theme-main-color;
        &:after{
            content:'';
            position:absolute;
            bottom:0;
            left:50%;
            transform:translateX(-50%);
            width: 42px;
            height:4px;
            background:$theme-main-color;
        }
    }
}
</style>